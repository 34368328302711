@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap";
:root {
  --bg-color: #fff;
  --text-color: #000;
  --primary-color: #f26b38;
  --secondary-color: #ede574;
  --nav-bg-color: #fff;
  --border-color: #f26b38;
  --footer-bg-color: white;
  --card-border-color: #dfdfdf;
  --tech-title-color: #757575;
  --award-title-color: #757575;
  --active-nav-color: #f26b38;
}

html[data-theme="dark"] {
  --bg-color: #1a1a1a;
  --text-color: #fff;
  --primary-color: coral;
  --secondary-color: gold;
  --nav-bg-color: #2a2a2a;
  --border-color: coral;
  --footer-bg-color: #2a2a2a;
  --card-border-color: #4a4a4a;
  --tech-title-color: #b0b0b0;
  --award-title-color: #b0b0b0;
  --active-nav-color: coral;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto Mono;
}

html {
  scroll-behavior: smooth;
}

#home {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 15%;
}

.main-container {
  scroll-snap-type: y mandatory;
}

.text-center, .aboutMe-container, .awards-container, .projects-container {
  scroll-snap-align: start;
}

#about, #awards, #projects {
  padding-top: 8%;
}

.dark-mode {
  filter: invert(1) heu-rotate(180deg);
  background-color: #4e4e4c;
}

.left-line {
  z-index: 1000;
  border-left: 30px solid #f26b38;
  height: 100vh;
  position: fixed;
  left: 0;
}

.right-line {
  z-index: 1000;
  border-right: 30px solid #f26b38;
  height: 100vh;
  position: fixed;
  right: 0;
}

.top-line {
  z-index: 1000;
  border-top: 30px solid #f26b38;
  width: 100%;
  position: sticky;
  top: 0;
}

.bottom-line {
  z-index: 1000;
  border-bottom: 30px solid #f26b38;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.footer {
  color: #000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  font-family: Roboto Mono;
  display: flex;
}

.footer > h5 {
  margin: 0;
  padding: 0;
}

.footer > .love > .heart {
  color: #a83f39;
}

.main-container {
  scroll-snap-type: y proximity;
  width: 100%;
  max-width: 1356px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.child-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-center {
  flex-flow: column wrap;
  width: 100%;
  font-size: 2rem;
  display: flex;
  text-align: center !important;
}

.text-center > h5 {
  font-family: Roboto Mono;
  font-size: 1.5rem;
}

.nav {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: 50px;
  position: fixed;
  right: 0;
}

.nav > .nav-btn {
  display: none;
}

.nav-collapse {
  width: 100%;
}

.nav-collapse > .nav-links {
  height: 50px;
  margin-right: 30px;
  font-family: Roboto Mono;
  font-size: 1em;
}

.nav-links > ul {
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  display: flex;
}

.nav-links > ul > li {
  margin: 10px 30px 0 0;
}

.nav-links > ul > li > a {
  color: var(--text-color);
  cursor: pointer;
  text-decoration: none;
}

.nav > .nav-collapse > .nav-links > ul > .active {
  color: var(--active-nav-color);
  text-emphasis: var(--text);
}

.nav-links > ul > li > a:hover {
  color: var(--active-nav-color);
  text-decoration: none;
}

.nav > #nav-check {
  display: none;
}

.aboutMe-container {
  height: min-content;
  position: relative;
  top: 10%;
}

.grid-container {
  grid-template-rows: 70px repeat(2, minmax(0, 1fr)) 70px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
  display: grid;
}

.about-title-1 {
  grid-area: 1 / 1 / 2 / -2;
  align-self: center;
  margin-left: 2%;
}

.img {
  grid-area: 2 / 1 / 4 / 3;
  place-self: flex-start center;
}

.my-pic-1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.content-1 {
  grid-area: 2 / 3 / 4 / -1;
  place-content: center;
  padding-right: 1rem;
  line-height: 35px;
}

.button-1 {
  grid-area: 4 / 3 / -1 / -1;
  justify-self: flex-start;
  padding-top: 1rem;
  font-size: small;
}

.button-1 > a {
  color: #fff;
  background-color: #f26b38;
  border: 2px solid #fff;
  padding: 8px;
}

.button-1 > a:hover {
  color: #f26b38;
  background-color: #fff;
  border: 1px solid #f26b38;
  text-decoration: none;
}

.exp-and-edu-1 {
  height: 100%;
  margin-top: 10%;
}

.col-2-container {
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.small-box {
  color: #f26b38;
  border: 1px solid #f26b38;
  font-family: Roboto Mono;
  font-size: small;
  display: inline;
}

.education {
  padding-left: 15%;
  font-family: Roboto Mono;
  font-weight: bolder;
}

.experience {
  border-left: 1px solid #f26b38;
  padding-left: 15%;
  font-family: Roboto Mono;
  font-weight: bolder;
}

.experience > h3, .education > h3, .about-title-1 > h3, .projects > h3, .awards > h3 {
  margin-left: 3%;
  font-family: Roboto Mono;
  font-size: 13px;
  display: inline;
  box-shadow: inset 0 -6px #ede574;
}

.card-title-1 {
  color: #141414;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-desc-1 {
  margin-bottom: 15%;
  font-size: 13px;
}

.cards-container {
  width: auto;
  height: 100%;
}

.cards-grid {
  flex-flow: wrap;
  justify-content: space-around;
  display: flex;
}

.github-card, .stackoverflow-card {
  flex: 1;
  max-width: 400px;
}

.gh-card, .so-card {
  flex: 1;
  place-self: center flex-end;
  width: 100%;
  height: 100%;
  margin: 2%;
  padding: 2%;
}

.projects {
  width: 100%;
  height: 100%;
  margin: 0 0 2% 0%;
  font-family: Roboto Mono;
}

.projects-container {
  flex-flow: wrap;
  justify-content: space-between;
  margin: 2% 2% 10%;
  display: flex;
}

.projects-container > .project-1, .projects-container > .project-2, .projects-container > .project-3, .projects-container > .project-4 {
  border: 1px solid #dfdfdf;
  border-top: 5px solid #f26b38;
  flex: 1;
  grid-template-rows: repeat(3, minmax(50px, 1fr));
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  width: max-content;
  margin: 1%;
  padding: 20px;
  display: grid;
}

.tech-icons {
  width: 27px;
  height: 27px;
  margin: 2%;
}

.tech-title {
  color: #757575;
  font-size: 13px;
  font-weight: bolder;
}

.inc-width {
  width: 55px;
}

.project-title-1 {
  grid-row: 1 / 2;
  align-self: center;
  font-size: 2em;
}

.tech-stack-1 {
  grid-row: 2 / 3;
}

.project-desc-1 {
  text-align: justify;
  grid-area: 3 / 1 / 4 / 3;
}

.project-img-1 {
  grid-area: 1 / 2 / 3 / 3;
  place-self: flex-start flex-end;
  width: 100%;
  height: auto;
  margin: 3%;
  padding: 7%;
}

.project-img-2 {
  grid-area: 1 / 2 / 3 / 3;
  place-self: flex-start flex-end;
  width: 60%;
  height: auto;
  margin: 4%;
}

.project-img-4 {
  grid-area: 1 / 2 / 3 / 3;
  place-self: flex-start flex-end;
  width: 60%;
  height: auto;
  padding-left: 10%;
}

.awards {
  width: 100%;
  height: 100%;
  margin: 0 0 2% 0%;
  font-family: Roboto Mono;
}

.awards-container {
  flex-flow: wrap;
  justify-content: space-between;
  margin: 2%;
  display: flex;
}

.awards-container > .award-1, .awards-container > .award-2, .awards-container > .award-3, .awards-container > .award-4, .awards-container > .award-5, .awards-container > .award-6 {
  border: 1px solid #dfdfdf;
  border-top: 5px solid #f26b38;
  flex: 1;
  grid-template-columns: repeat(2, minmax(211px, 1fr));
  width: 100%;
  margin: .5%;
  padding: 20px;
  display: grid;
}

.award-left-side {
  grid-column: 1 / 2;
  padding: 5%;
  font-size: 16px;
}

.award-right-side {
  grid-column: 2 / 3;
  display: grid;
}

.award-img-1 {
  border-radius: 10%;
  place-self: flex-start flex-end;
  width: 100%;
  height: auto;
  margin-top: 5%;
  padding: 2%;
}

.award-img-2 {
  place-self: flex-start flex-end;
  width: 90%;
  height: auto;
  margin-top: 4%;
}

.award-img-3 {
  place-self: flex-start flex-end;
  width: 80%;
  height: auto;
  margin-top: 10%;
}

.award-img-4 {
  place-self: flex-start flex-end;
  width: 80%;
  height: auto;
}

.award-img-5 {
  place-self: flex-start flex-end;
  width: 60%;
  height: auto;
}

.award-title {
  text-transform: uppercase;
  color: #757575;
  font-size: 18px;
  font-weight: bolder;
}

.award-program, .award-org {
  margin-top: 10%;
}

.award-link > div {
  margin-top: 5%;
}

.tech-stack-container {
  width: 100vw;
  height: 100px;
  position: relative;
}

@media only screen and (width <= 600px) {
  body {
    background-color: #add8e6;
    font-family: Roboto Mono;
  }

  .main-container {
    scroll-snap-type: y mandatory;
  }

  .grid-container, .awards, .projects {
    scroll-snap-align: start;
    padding-top: 8%;
  }

  .left-line {
    border-left: 15px solid #f26b38;
  }

  .right-line {
    border-right: 15px solid #f26b38;
  }

  .top-line {
    border-top: 15px solid #f26b38;
  }

  .bottom-line {
    border-bottom: 15px solid #f26b38;
  }

  .main-container {
    width: 100%;
    max-width: 768px;
  }

  .child-center {
    margin-top: 5%;
  }

  .img {
    grid-row: 2 / 4;
  }

  .button-1 {
    margin-left: 5%;
  }

  .text-center > h1 {
    font-size: 6.4vw;
  }

  .text-center > h5 {
    font-size: 4vw;
  }

  .grid-container {
    flex-flow: column wrap;
    width: 100%;
    display: flex;
  }

  .about-title-1 {
    margin: 2%;
  }

  .img {
    place-self: center;
  }

  .my-pic-1 {
    margin: 2%;
  }

  .content-1 {
    text-align: justify;
    margin: 3%;
    padding: 3%;
  }

  .button-1 {
    width: max-content;
    padding-top: 0;
  }

  .col-2-container {
    flex-flow: column wrap;
    display: flex;
  }

  .education, .experience {
    padding: 5%;
  }

  .experience > h3, .education > h3, .about-title-1 > h3, .projects > h3, .awards > h3 {
    margin-left: 9%;
    font-size: 11px;
  }

  .awards-container, .projects-container {
    flex-flow: wrap;
    justify-content: space-between;
    margin: 4%;
    padding: 6%;
    display: flex;
  }

  .awards-container > .award-1, .awards-container > .award-2, .awards-container > .award-3, .awards-container > .award-4, .awards-container > .award-5, .awards-container > .award-6 {
    border: 1px solid #dfdfdf;
    border-top: 5px solid #f26b38;
    flex: 1;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    width: max-content;
    margin: .5%;
    padding: 5%;
    display: grid;
  }

  .award-left-side {
    font-size: 12px;
  }

  .projects-container > .project-1, .projects-container > .project-2, .projects-container > .project-3, .projects-container > .project-4 {
    border: 1px solid #dfdfdf;
    border-top: 5px solid #f26b38;
    flex: 1;
    grid-template-rows: repeat(3, minmax(50px, 1fr));
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    width: max-content;
    margin: 1%;
    padding: 5%;
    display: grid;
  }

  .project-title-1 {
    grid-row: 1 / 2;
    justify-content: flex-start;
    align-self: flex-start;
    font-size: 1.5em;
  }

  .nav {
    margin: 10px 0 10px 10px;
    padding: 5%;
    top: 0;
  }

  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .nav > .nav-btn > label {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    padding: 13px;
    display: inline-block;
  }

  .nav > .nav-btn > label:hover, .nav #nav-check:checked ~ .nav-btn > label {
    background-color: #fff0;
  }

  .nav > .nav-btn > label > span {
    border-top: 2px solid #000;
    width: 25px;
    height: 10px;
    display: block;
  }

  .nav > .nav-collapse > .nav-links {
    z-index: 2;
    background-color: #fdfdfd;
    width: 100%;
    height: 0;
    transition: all .3s ease-in;
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-y: hidden;
  }

  .nav-links > ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nav-links > ul > li {
    width: 100%;
    padding: 2%;
  }

  .nav > #nav-check:not(:checked) ~ .nav-collapse > .nav-links {
    height: 0;
  }

  .nav > #nav-check:checked ~ .nav-collapse > .nav-links {
    z-index: 2;
    height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .footer {
    margin-bottom: 20px;
  }

  .footer > h5 {
    font-size: 13px;
  }

  .footer > .love > .heart {
    color: #a83f39;
  }
}

:root {
  --text: #5362f6;
  --shadow: #c9a7dd;
}

.playful {
  color: var(--text);
  text-shadow: .3125vw .3125vw var(--shadow);
}

.glitch {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  flex: 1;
  width: max-content;
  font-family: Roboto Mono;
  font-size: 4.8vw;
  font-weight: bold;
  animation: 1s linear infinite alternate-reverse glitch-skew;
  position: relative;
}

.glitch:before {
  content: attr(data-text);
  text-shadow: -2px 0 #ff00c1;
  clip: rect(44px, 450px, 56px, 0);
  width: 100%;
  height: 100%;
  animation: 5s linear infinite alternate-reverse glitch-anim;
  position: absolute;
  top: 0;
  left: 2px;
}

.glitch:after {
  content: attr(data-text);
  text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
  width: 100%;
  height: 100%;
  animation: 1s linear infinite alternate-reverse glitch-anim2;
  position: absolute;
  top: 0;
  left: -2px;
}

@keyframes glitch-anim {
  0% {
    clip: rect(7px, 9999px, 59px, 0);
    transform: skew(.48deg);
  }

  5% {
    clip: rect(11px, 9999px, 40px, 0);
    transform: skew(.45deg);
  }

  10% {
    clip: rect(86px, 9999px, 100px, 0);
    transform: skew(.8deg);
  }

  15% {
    clip: rect(96px, 9999px, 75px, 0);
    transform: skew(.82deg);
  }

  20% {
    clip: rect(44px, 9999px, 12px, 0);
    transform: skew(.32deg);
  }

  25% {
    clip: rect(44px, 9999px, 87px, 0);
    transform: skew(.43deg);
  }

  30% {
    clip: rect(63px, 9999px, 17px, 0);
    transform: skew(.8deg);
  }

  35% {
    clip: rect(77px, 9999px, 48px, 0);
    transform: skew(.11deg);
  }

  40% {
    clip: rect(32px, 9999px, 95px, 0);
    transform: skew(.51deg);
  }

  45% {
    clip: rect(11px, 9999px, 77px, 0);
    transform: skew(.84deg);
  }

  50% {
    clip: rect(80px, 9999px, 21px, 0);
    transform: skew(.32deg);
  }

  55% {
    clip: rect(84px, 9999px, 94px, 0);
    transform: skew(.13deg);
  }

  60% {
    clip: rect(11px, 9999px, 30px, 0);
    transform: skew(.71deg);
  }

  65% {
    clip: rect(17px, 9999px, 3px, 0);
    transform: skew(.87deg);
  }

  70% {
    clip: rect(17px, 9999px, 63px, 0);
    transform: skew(.94deg);
  }

  75% {
    clip: rect(57px, 9999px, 68px, 0);
    transform: skew(.47deg);
  }

  80% {
    clip: rect(95px, 9999px, 56px, 0);
    transform: skew(.56deg);
  }

  85% {
    clip: rect(94px, 9999px, 7px, 0);
    transform: skew(.25deg);
  }

  90% {
    clip: rect(57px, 9999px, 66px, 0);
    transform: skew(.79deg);
  }

  95% {
    clip: rect(83px, 9999px, 50px, 0);
    transform: skew(.49deg);
  }

  100% {
    clip: rect(56px, 9999px, 54px, 0);
    transform: skew(.88deg);
  }
}

@keyframes glitch-anim2 {
  0% {
    clip: rect(66px, 9999px, 9px, 0);
    transform: skew(.61deg);
  }

  5% {
    clip: rect(1px, 9999px, 33px, 0);
    transform: skew(.86deg);
  }

  10% {
    clip: rect(58px, 9999px, 24px, 0);
    transform: skew(.08deg);
  }

  15% {
    clip: rect(74px, 9999px, 44px, 0);
    transform: skew(.41deg);
  }

  20% {
    clip: rect(93px, 9999px, 71px, 0);
    transform: skew(.9deg);
  }

  25% {
    clip: rect(61px, 9999px, 57px, 0);
    transform: skew(.31deg);
  }

  30% {
    clip: rect(41px, 9999px, 42px, 0);
    transform: skew(.06deg);
  }

  35% {
    clip: rect(39px, 9999px, 46px, 0);
    transform: skew(.17deg);
  }

  40% {
    clip: rect(1px, 9999px, 45px, 0);
    transform: skew(.25deg);
  }

  45% {
    clip: rect(14px, 9999px, 82px, 0);
    transform: skew(.25deg);
  }

  50% {
    clip: rect(28px, 9999px, 23px, 0);
    transform: skew(.58deg);
  }

  55% {
    clip: rect(74px, 9999px, 19px, 0);
    transform: skew(.6deg);
  }

  60% {
    clip: rect(88px, 9999px, 77px, 0);
    transform: skew(.46deg);
  }

  65% {
    clip: rect(97px, 9999px, 1px, 0);
    transform: skew(.99deg);
  }

  70% {
    clip: rect(51px, 9999px, 70px, 0);
    transform: skew(.02deg);
  }

  75% {
    clip: rect(54px, 9999px, 44px, 0);
    transform: skew(.67deg);
  }

  80% {
    clip: rect(53px, 9999px, 79px, 0);
    transform: skew(.68deg);
  }

  85% {
    clip: rect(49px, 9999px, 4px, 0);
    transform: skew(.21deg);
  }

  90% {
    clip: rect(7px, 9999px, 24px, 0);
    transform: skew(.06deg);
  }

  95% {
    clip: rect(5px, 9999px, 1px, 0);
    transform: skew(.2deg);
  }

  100% {
    clip: rect(34px, 9999px, 92px, 0);
    transform: skew(.84deg);
  }
}

@keyframes glitch-skew {
  0% {
    transform: skew(-1deg);
  }

  10% {
    transform: skew(3deg);
  }

  20% {
    transform: skew(-2deg);
  }

  30% {
    transform: skew(1deg);
  }

  40% {
    transform: skew(4deg);
  }

  50% {
    transform: skew(-2deg);
  }

  60% {
    transform: skew(4deg);
  }

  70% {
    transform: skew(3deg);
  }

  80% {
    transform: skew(0deg);
  }

  90% {
    transform: skew(3deg);
  }

  100% {
    transform: skew(1deg);
  }
}

/*# sourceMappingURL=index.aecb2ddb.css.map */
